<template>

  <!-- Featured Opps Panel -->
  <div id="featured-opps-panel">

    <!-- Featured Opportunities Heading -->
    <j-heading heading="Featured Opportunities" />

    <!-- Featured Opps Loop -->
    <OppTile
      v-for="opp in featured.getModels()"
      :key="opp.tag19"
      :opp="opp"
      class="mb-4"
    >
      <template #footer>

        <!-- Link to item -->
        <router-link :to="{ name: 'opps-details', params: { tag19: opp.tag19 }}"><strong>Details</strong></router-link>

        <v-spacer />

        <!-- Request Button -->
        <OppRequest
          :opp="opp"
          class="my-2"
        />
      </template>
    </OppTile>

    <!-- Load More -->
    <div v-intersect.quiet="handleMore">
      <p v-if="featured.length">
        Showing {{ featured.length }} of {{ featured.get('count') }} entries
      </p>
      <p v-if="loading">
        <j-icon
          icon="star"
          class="mr-1"
          spin
        />
        Loading...
      </p>
    </div>
  </div>
</template>

<script>

// Components
import OppRequest from '@/components/widgets/Opps/OppRequest'
import OppTile from '@/components/widgets/Opps/OppTile'

// Vuex
import { createNamespacedHelpers } from 'vuex'
const { mapState, mapGetters, mapActions } = createNamespacedHelpers('opps')

export default {
  name: 'FeaturedOppsPanel',
  // -------------
  // Components ==
  // -------------
  components: {
    OppRequest,
    OppTile
  },
  // -------------
  // Middleware ==
  // -------------
  beforeRouteEnter(_to, _from, next) {
    next(vm => {
      // Get featured opps
      vm.fetchOpps({ listing: 'featured' })
    })
  },
  // -----------
  // Computed ==
  // -----------
  computed: {
    // State
    ...mapState([
      'featured'
    ]),
    // Getters
    ...mapGetters([
      'loading'
    ])
  },
  // ----------
  // Methods ==
  // ----------
  methods: {
    // Actions
    ...mapActions([
      'fetchOpps',
      'fetchMoreOpps'
    ]),
    // Handlers
    handleMore (entries, observer, isIntersecting) {
      if (isIntersecting) {
        this.fetchMoreOpps({ listing: 'featured' })
      }
    }
  }
}
</script>
